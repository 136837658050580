import {
  Typography,
  useTheme,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
  IconButton,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import useStyles from "./styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import apiCliente from "../../../services/apiCliente";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  DescriptionOutlined,
  ExpandMore,
  MoneyOffOutlined,
  TaskOutlined,
  RequestPageOutlined,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
export default function Financeiro() {
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [alertSize, setAlertSize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCobranca, setLoadingCobranca] = useState(false);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [contratos, setContratos] = useState([]);
  const [cobrancas, setCobrancas] = useState({});
  const styles = useStyles(theme);

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    getContratos();
  }, []);

  async function getContratos() {
    setLoading(true);

    try {
      const response = await apiCliente.post("/brbyte/cliente/contrato", {
        cnpj: JSON.parse(localStorage.getItem("user"))?.usuarioCliente?.cliente
          ?.cnpj,
      });

      setContratos(response?.data?.contratosDetails);
      response?.data?.contratosDetails?.forEach(async (contr) => {
        const cobrancas = await getCobrancas(contr?.contract_number);

        setCobrancas((prevCobrancas) => ({
          ...prevCobrancas,
          [contr.contract_number]: cobrancas,
        }));
      });

      return response?.data?.contratosDetails;
    } catch (error) {
      if (error?.response?.status == 404) {
        setSnackbar({
          children: `${
            error?.response?.data?.error ||
            "Error: Não foi possivel encontrar contratos"
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  async function getCobrancas(contrato_number) {
    setLoadingCobranca(true);

    try {
      const response = await apiCliente.post(
        "/brbyte/cliente/contrato/cobranca",
        {
          contrato_number: contrato_number,
        }
      );

      return response?.data?.results;
    } catch (error) {
      if (error?.response?.status == 404) {
        setSnackbar({
          children: `${
            error?.response?.data?.error ||
            "Error: Não foi possivel encontrar contratos"
          }`,
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      }
    } finally {
      setLoadingCobranca(false);
    }
  }

  const converteTipo = {
    0: "Contratual",
  };

  const getRows = (contratoNumber) => {
    let data =
      cobrancas[contratoNumber]?.map((cobranca) => {
        const dadosRows = {
          id: cobranca?.invoice_pk || "",
          valor: "R$" + cobranca?.invoice_amount_document || "-",
          vencimento:
            dayjs(cobranca?.invoice_date_due).format("DD-MM-YYYY") || "-",
          tipo: converteTipo[cobranca?.invoice_type] || "-",
          pagamento: "R$" + cobranca?.invoice_amount_nf || "-",
          pagamentoData: cobranca?.invoice_date_credit
            ? dayjs(cobranca?.invoice_date_credit).format("DD-MM-YYYY")
            : "Não efetuado",

          boleto: cobranca?.invoice_print_link || "-",
          recibo: cobranca?.invoice_amount_paid,
        };

        return dadosRows;
      }) || [];
    if (data.length > 0) {
      data.sort((a, b) => a.id - b.id);
    }
    return data;
  };

  const columns = [
    {
      field: "id",
      headerName: "Cobrança",
      minWidth: 30,
      flex: 1,
      cellClassName: (params) => {
        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
        });
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      minWidth: 120,
      flex: 1,
      cellClassName: (params) => {
        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
        });
      },
    },
    {
      field: "vencimento",
      headerName: "Data de vencimento",
      minWidth: 120,
      flex: 1,
      cellClassName: (params) => {
        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
        });
      },
    },
    {
      field: "tipo",
      headerName: "Tipo",
      minWidth: 120,
      flex: 1,
      cellClassName: (params) => {
        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
        });
      },
    },

    {
      field: "pagamentoData",
      headerName: "Data do pagamento",
      minWidth: 120,
      flex: 1,
      cellClassName: (params) => {
        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
        });
      },
    },
    {
      field: "boleto",
      headerName: "Boleto",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              window.open(params?.row?.boleto, "_blank");
            }}
          >
            <RequestPageOutlined />{" "}
          </IconButton>
        );
      },
      cellClassName: (params) => {
        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
        });
      },
    },
    {
      field: "recibo",
      headerName: "Recibo",
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            disabled={!params?.row?.recibo}
            onClick={() =>
              window.open(`/financeiro/recibo/${params?.row?.id}`, "_blank")
            }
          >
            <DescriptionOutlined />{" "}
          </IconButton>
        );
      },
      cellClassName: (params) => {
        return clsx("super-app", {
          pago: params?.row?.pagamentoData !== "Não efetuado",
        });
      },
    },
  ];
  /* data de pagamento invoice_date_credit
data de vencimento invoice_date_due */
  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <Typography sx={styles.title}>Contratos</Typography>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          {Array.isArray(contratos) &&
            contratos?.map((contrato, index) => (
              <>
                <Accordion
                  key={index}
                  //variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
                  sx={styles.accordion}
                  elevation={2}
                  disableGutters
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box sx={styles.containerContrato}>
                      {" "}
                      <Typography sx={styles.itemTitleContrato}>
                        <DescriptionOutlined
                          color=""
                          sx={styles.iconTitleContrato}
                        />
                        Contrato
                      </Typography>
                      <Typography sx={styles.itemTextContrato}>
                        {contrato?.contract_number}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      direction={"row"}
                      sx={styles.accordionSummaryBox}
                      spacing={2}
                      columns={
                        windowWith > 1400 ? 16 : windowWith > 900 ? 10 : 8
                      }
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Typography sx={styles.itemTitle}>
                          <AttachMoneyOutlined
                            color="secondary"
                            sx={styles.iconTitle}
                          />
                          Valor
                        </Typography>
                        <Typography sx={styles.itemText}>
                          <Typography
                            sx={{ fontWeight: 600, opacity: "0.7", mr: "2px" }}
                          >
                            {" "}
                            R$:{" "}
                          </Typography>
                          {parseFloat(contrato?.contract_amount)?.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography sx={styles.itemTitle}>
                            <CalendarTodayOutlined
                              color="secondary"
                              sx={styles.iconTitle}
                            />
                            Dia
                          </Typography>
                          <Typography sx={styles.itemText}>
                            {contrato?.contract_pay_day}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Typography sx={styles.itemTitle}>
                          <MoneyOffOutlined
                            color="secondary"
                            sx={styles.iconTitle}
                          />
                          Desconto
                        </Typography>
                        <Typography sx={styles.itemText}>
                          <Typography
                            sx={{ fontWeight: 600, opacity: "0.7", mr: "2px" }}
                          >
                            {" "}
                            R$:{" "}
                          </Typography>
                          {contrato?.contract_pay_discount}
                        </Typography>
                      </Grid>

                      <Grid item xs={3} sx={styles.accordionSummaryItem}>
                        <Typography sx={styles.itemTitle}>
                          <TaskOutlined
                            color="secondary"
                            sx={styles.iconTitle}
                          />
                          Ativação
                        </Typography>
                        <Typography sx={styles.itemText}>
                          {dayjs(contrato?.contract_date_activation).format(
                            "DD-MM-YYYY"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      onClick={() => {
                        contrato?.contract_sign_info[1]
                          ? window.open(contrato?.contract_sign_info[1])
                          : setSnackbar({
                              children: `${"Não foi possível encontrar arquivo. Entre em contato com o suporte."}`,
                              severity: "info",
                            });
                      }}
                      sx={{ height: "40px" }}
                      variant="outlined"
                    >
                      Contrato
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>
                    {loadingCobranca ? (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <CircularProgress size={60} />
                      </Box>
                    ) : (
                      <DataGrid
                        rows={getRows(contrato?.contract_number)}
                        columns={columns}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        hideFooter={false}
                        autoHeight={true}
                        density="compact"
                        pageSizeOptions={[5, 10]}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 1, pageSize: 5 },
                          },
                        }}
                        sx={{
                          display: "grid",
                          "&.MuiDataGrid-root": { borderStyle: "none" },
                          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                            {
                              outline: "none !important",
                            },
                          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell":
                            {
                              /* py: "15px", */
                            },
                          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell teste===1":
                            {
                              /* py: "15px", */
                              backgroundColor: "red",
                            },
                          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell":
                            {
                              py: "15px",
                            },
                          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                            {
                              py: "22px",
                            },
                          '&.MuiDataGrid-root .MuiDataGrid-container--top [role="row"], & .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"]':
                            {
                              backgroundColor: "background.paper",
                            },
                          "& .super-app.pago": {
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "#00a76f77"
                                : "#c8e6c9",
                          },
                        }}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
          <Snackbar
            autoHideDuration={3000}
            open={alertSize}
            onClose={() => {
              setAlertSize(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="error">Tamanho máximo permitido: 300KB</Alert>
          </Snackbar>
          {!!snackbar && (
            <Snackbar
              open
              onClose={() => setSnackbar(null)}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert {...snackbar} onClose={() => setSnackbar(null)} />
            </Snackbar>
          )}
        </Box>
      )}
    </Box>
  );
}
